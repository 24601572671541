import pluralize from "pluralize";
import {EntrySeries, EntryTag, useEntries} from "../../api";
import {Link} from "react-router-dom";
import Page from "../Page";
import {StackedListItem} from "../StackedListItem";
import {StackedList} from "../StackedList";
import Chip from "@mui/joy/Chip";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PageTitle from "../PageTitle";
import LoadingScreen from "../LoadingScreen";
import {alpha} from '@mui/material/styles';

interface EntryTagsProps {
    tags: EntryTag[];
}

const EntryTags = ({tags}: EntryTagsProps) => {
    if (tags.length === 0) {
        return <div/>;
    }

    return (
        <Stack direction="row" spacing={0.5}>
            {
                tags.map((tag) => (
                    <Chip key={tag.id}
                          slotProps={{
                              action: {
                                  component: Link,
                                  to: `/tags/${tag.id}`,
                                  sx: {backgroundColor: tag.colour_background, color: tag.colour_foreground}
                              }
                          }}
                          variant="outlined"
                          sx={{
                              backgroundColor: tag.colour_background,
                              color: tag.colour_foreground,
                              "--Chip-radius": "6px",
                              '--variant-outlinedBorder': alpha(tag.colour_foreground, 0.2)
                          }}>
                        {tag.name}
                    </Chip>
                ))
            }
        </Stack>
    );
};

interface EntrySeriesChipProps {
    series: EntrySeries;
}

const EntrySeriesChip = ({series}: EntrySeriesChipProps) => {
    // TODO: Figure out why this is an empty object when no series is assigned
    if (!series || !series.id) {
        return <div>&nbsp;</div>;
    }

    return (
        <>
            <Chip
                color="neutral"
                startDecorator={
                    <LibraryBooksIcon/>
                }
                onClick={() => alert('You clicked the Joy Chip!')}
                sx={{
                    "--Chip-radius": "6px"
                }}
            >
                Part of the <Link to={`/series/${series.id}`}><strong>{series.name}</strong></Link> series
            </Chip>
        </>
    );
};

export default function EntriesScreen() {
    const {entries: allEntries, isError, isLoading} = useEntries();

    if (isLoading) {
        return <LoadingScreen/>;
    }

    if (isError) {
        return <div>Error loading entries</div>;
    }

    if (!allEntries) {
        return <div>There are no entries</div>;
    }

    const entries = allEntries.items.map((entry) => {
        // TODO: This title not descriptive or precise enough
        return <StackedListItem key={entry.id}
                                href={`/entries/${entry.id}`}
                                title={entry.date}
                                titleRight={<Chip>{entry.word_count} {pluralize('word', entry.word_count)}</Chip>}
                                subtitle={<EntryTags tags={entry.tags}/>}
                                subtitleRight={<EntrySeriesChip series={entry.series}/>}/>;
    });

    // TODO: This makes the header a bit taller than it is on the other pages
    const titleExtra = (
        <Stack direction="row" spacing={2} alignItems="center">
            <Stack direction="column">
                <Typography level="body-xs" color="neutral">
                    Total Entries
                </Typography>

                <Typography level="body-lg">
                    {allEntries.meta.entries_total}
                </Typography>
            </Stack>
            <Stack direction="column">
                <Typography level="body-xs" color="neutral">
                    Missing Entries
                </Typography>

                <Typography level="body-lg">
                    {allEntries.meta.entries_missing}
                </Typography>
            </Stack>
            <Stack direction="column">
                <Typography level="body-xs" color="neutral">
                    # of Words
                </Typography>

                <Typography level="body-lg">
                    {allEntries.meta.entries_words}
                </Typography>
            </Stack>
        </Stack>
    );

    return (
        <Page title={
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <PageTitle title="All entries"/>

                {titleExtra}
            </Stack>
        }>
            <StackedList>
                {entries}
            </StackedList>
        </Page>
    );
}
