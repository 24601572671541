import {Link as RouterLink} from "react-router-dom";
import Alert from '@mui/joy/Alert';
import Card from '@mui/joy/Card';
import Grid from '@mui/joy/Grid';
import Link from '@mui/joy/Link';
import Stack from '@mui/joy/Stack';
import Typography from "@mui/joy/Typography";
import AspectRatio from "@mui/joy/AspectRatio";
import React from "react";

export interface Action {
    title: string;
    description: string;
    href: string | (() => void);
    icon: React.ElementType;
    iconForeground: string;
    iconBackground: string;
}

interface ActionGridProps {
    actions: Action[];
    xs?: boolean | 'auto' | number;
}

export default function ActionGrid({actions, xs = 6}: ActionGridProps) {
    return (
        <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
            {actions.map(action => {
                const content = (
                    <Stack direction="column">
                        <AspectRatio ratio={1} sx={{width: 48}}>
                            <Alert variant="soft"
                                   sx={{backgroundColor: action.iconBackground, color: action.iconForeground}}>
                                {/* TODO: This icon size should be bigger. fontSize doesn't seem to be working */}
                                <action.icon fontSize="large" aria-hidden="true"/>
                            </Alert>
                        </AspectRatio>

                        <Typography level="title-lg" paddingTop={3}>
                            {action.title}
                        </Typography>
                        <Typography level="body-sm" paddingY={1}>
                            {action.description}
                        </Typography>
                    </Stack>
                );

                let child;
                if (typeof action.href === 'function') {
                    child = (
                        <Link underline="none" onClick={action.href}>
                            {content}
                        </Link>
                    )
                } else {
                    child = (
                        <RouterLink to={action.href}>
                            {content}
                        </RouterLink>
                    )
                }

                return (
                    <Grid key={action.title} xs={xs}>
                        <Card>
                            {child}
                        </Card>
                    </Grid>

                )
            })}
        </Grid>
    );
}
