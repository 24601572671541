import * as React from 'react';
import {useColorScheme} from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';

import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

import {Link, useLocation} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import {UseAuthenticator} from "@aws-amplify/ui-react";
import {Create} from "@mui/icons-material";

function ColorSchemeToggle() {
    const {mode, setMode} = useColorScheme();
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) {
        return <IconButton size="sm" variant="outlined" color="primary"/>;
    }
    return (
        <Tooltip title="Change theme" variant="outlined">
            <IconButton
                id="toggle-mode"
                size="sm"
                variant="plain"
                color="neutral"
                sx={{alignSelf: 'center'}}
                onClick={() => {
                    if (mode === 'light') {
                        setMode('dark');
                    } else {
                        setMode('light');
                    }
                }}
            >
                {mode === 'light' ? <DarkModeRoundedIcon/> : <LightModeRoundedIcon/>}
            </IconButton>
        </Tooltip>
    );
}

interface HeaderProps {
    signOut: UseAuthenticator['signOut'];
}

export default function Header({signOut}: HeaderProps) {
    const [open, setOpen] = React.useState(false);
    const location = useLocation();
    const {attributes} = useAuth();

    if (!attributes) {
        return <></>;
    }

    const navigation = [
        {name: 'Today', href: '/'},
        {name: 'Entries', href: '/entries'},
        {name: 'Series', href: '/series'},
        {name: 'Tags', href: '/tags'},
        {name: 'Stats', href: '/stats'},
        {name: 'Goals', href: '/goals'},
    ];

    const userNavigation = [
        {name: 'Your Profile', href: '/profile'},
        {name: 'Settings', href: '/settings'},
    ];

    const handleLogout = async () => {
        await signOut();
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between',
            }}
        >
            <Stack
                direction="row"
                spacing={2}
                width="100%"
            >
                <Stack direction="row"
                       justifyContent="center"
                       alignItems="center"
                       spacing={1}>
                    <IconButton
                        size="md"
                        variant="outlined"
                        color="neutral"
                        sx={{
                            display: {xs: 'none', sm: 'inline-flex'},
                            borderRadius: '50%',
                        }}
                    >
                        <HistoryEduIcon/>
                    </IconButton>

                    {navigation.map((item) => {
                        const current = location.pathname === item.href;

                        return (
                            <Button
                                key={item.name}
                                variant="plain"
                                color="neutral"
                                aria-pressed={current ? 'true' : 'false'}
                                component={Link}
                                to={item.href}
                                size="sm"
                                sx={{alignSelf: 'center'}}
                            >
                                {item.name}
                            </Button>
                        );
                    })}
                </Stack>

                <Button variant="soft" color="primary" size="sm" startDecorator={<Create/>} >
                    <Link to="/entries/new">New Entry</Link>
                </Button>
            </Stack>
            <Box sx={{display: {xs: 'inline-flex', sm: 'none'}}}>
                <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
                    <MenuRoundedIcon/>
                </IconButton>
                <Drawer
                    sx={{display: {xs: 'inline-flex', sm: 'none'}}}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <ModalClose/>
                    <DialogTitle>Acme Co.</DialogTitle>
                    <Box sx={{px: 1}}>
                        {/* TODO: Implement the menu on mobile */}
                    </Box>
                </Drawer>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1.5,
                    alignItems: 'center',
                }}
            >
                <ColorSchemeToggle/>
                <Dropdown>
                    <MenuButton
                        variant="plain"
                        size="sm"
                        sx={{maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px'}}
                    >
                        <AccountCircleIcon sx={{maxWidth: '32px', maxHeight: '32px'}}/>
                    </MenuButton>
                    <Menu
                        placement="bottom-end"
                        size="sm"
                        sx={{
                            zIndex: '99999',
                            p: 1,
                            gap: 1,
                            '--ListItem-radius': 'var(--joy-radius-sm)',
                        }}
                    >
                        <MenuItem>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <AccountCircleIcon sx={{borderRadius: '50%'}}/>

                                <Box sx={{ml: 1.5}}>
                                    <Typography level="title-sm" textColor="text.primary">
                                        {attributes.name}
                                    </Typography>
                                    <Typography level="body-xs" textColor="text.tertiary">
                                        {attributes.email}
                                    </Typography>
                                </Box>
                            </Box>
                        </MenuItem>
                        <ListDivider/>

                        {userNavigation.map((item) => (
                            <MenuItem key={item.name} component={Link} to={item.href}>
                                {item.name}
                            </MenuItem>
                        ))}

                        <ListDivider/>

                        <MenuItem onClick={handleLogout}>
                            <LogoutRoundedIcon/>
                            Log out
                        </MenuItem>
                    </Menu>
                </Dropdown>
            </Box>
        </Box>
    );
}
