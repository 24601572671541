import Stack from '@mui/material/Stack';
import React from "react";

interface FormFieldsetProps {
    children: React.ReactNode;
}

export default function FormFieldset({ children }: FormFieldsetProps) {
    return (
        <Stack direction="column" spacing={ 3 }>
            { children }
        </Stack>
    )
}
