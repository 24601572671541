import React from "react";
import {EntryType, useTodaysPrompt} from "../../api.ts";
import Box from "@mui/joy/Box";
import Grid from "@mui/joy/Grid";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import Stack from "@mui/joy/Stack";
import SelectTags from "./SelectTags.tsx";
import SelectSerial from "./SelectSerial.tsx";
import Typography from "@mui/joy/Typography";
import LoadingScreen from "../LoadingScreen";
import Tooltip from "@mui/joy/Tooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ToggleButtonGroup from "@mui/joy/ToggleButtonGroup";
import Button from "@mui/joy/Button";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";

interface EditorProps {
    children: React.ReactNode;
    onChangeTags: (value: string[]) => void;
    onChangeSeries: (value: string | null) => void;
    onChangeType: (value: EntryType) => void;
    serial: string | null;
    tags: string[];
    type: EntryType;
}

export default function Editor({children, onChangeTags, onChangeSeries, onChangeType, serial, tags, type}: EditorProps) {
    return (
        <Box>
            <Grid container columns={8} spacing={2}>
                <Grid xs={8} md={6}>
                    {children}
                </Grid>

                <Grid xs={8} md={2}>
                    <Stack spacing={2}>
                        <SidebarElement title="Mode" tooltip="Pick a writing mode, based on how you feel like writing today">
                            <ToggleButtonGroup
                                value={type}
                                onChange={(_event, newValue) => {
                                    if (newValue) {
                                        onChangeType(newValue);
                                    }
                                }}
                            >
                                <Tooltip title="Write whatever you like, using your own inspiration.">
                                    <Button value="freeform" startDecorator={ <CreateOutlinedIcon />}>Freeform</Button>
                                </Tooltip>
                                <Tooltip title="Write using a daily prompt, if you're stuck for ideas!">
                                    <Button value="prompt" startDecorator={ <ChatOutlinedIcon />}>Prompt</Button>
                                </Tooltip>
                            </ToggleButtonGroup>
                        </SidebarElement>

                        {type === 'prompt' && (
                            <SidebarElement title="Prompt"
                                            tooltip="A daily prompt generated for inspiration. Feel free to use it, or not use it!">
                                <CurrentPrompt/>
                            </SidebarElement>
                        )}

                        <SidebarElement title="Series" tooltip="Assign today's entry to a serial, e.g. My Sci-Fi Book">
                            <SelectSerial onChange={onChangeSeries} value={serial}/>
                        </SidebarElement>

                        <SidebarElement title="Tags"
                                        tooltip="Assign some tags, which can be used to organise your entries">
                            <SelectTags onChange={onChangeTags} value={tags} />
                        </SidebarElement>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}


interface SidebarElementProps {
    children: React.ReactNode;
    title: string;
    tooltip: React.ReactNode;
}

const SidebarElement = ({children, title, tooltip}: SidebarElementProps) => {
    return (
        <Stack spacing={1.5}>
            <FormLabel>
                <Tooltip title={tooltip}>
                    <InfoOutlinedIcon sx={{fontSize: 16, mr: 0.5}}/>
                </Tooltip>

                {title}
            </FormLabel>
            <FormControl>
                {children}
            </FormControl>
        </Stack>
    );
}

const CurrentPrompt = () => {
    const {prompt, isError, isLoading} = useTodaysPrompt();

    if (isLoading) {
        return <LoadingScreen/>
    }

    if (!prompt || isError) {
        return <Typography>Unable to generate a prompt!</Typography>
    }

    return (
        <Typography>
            {prompt.text}
        </Typography>
    );
}
