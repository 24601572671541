import Page from "../Page";
import PageTitle from "../PageTitle";
import {Radio, RadioGroup} from "@mui/joy";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import {format, isValid} from "date-fns";
import FormInput from "../FormInput.tsx";
import FormActions from "../FormActions.tsx";
import FormSubmit from "../FormSubmit.tsx";
import {Field, Form, Formik, FormikErrors, FormikHelpers, useField, useFormikContext} from "formik";
import FormFieldset from "../FormFieldset.tsx";
import {useEffect} from "react";
import {fetcher} from "../../api.ts";
import {mutate} from "swr";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";

interface RadioInputProps {
    disabled?: boolean;
    helpText: string;
    label: string;
    value: string;
}

function RadioInput({disabled = false, helpText, label, value}: RadioInputProps) {
    return (
        <>
            <Radio
                disabled={disabled}
                value={value}
                label={label}
                slotProps={{input: {'aria-describedby': `${value}-helper`}}}
            />
            <FormHelperText id={`${value}-helper`}>
                {helpText}
            </FormHelperText>
        </>
    )
}

interface NewGoal {
    type: string;
    target: string;
    recurrence: string;
}

export default function NewGoalScreen() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const initialValues: NewGoal = {
        type: '',
        target: '',
        recurrence: '',
    }

    const validate = (values: NewGoal) => {
        const errors: FormikErrors<NewGoal> = {};
        if (!values.type) {
            errors.type = 'A type needs to be selected';
        }

        if (!values.recurrence) {
            errors.recurrence = 'A recurrence needs to be selected';
        }

        if (!values.target) {
            errors.target = 'A target needs to be set';
        }

        if (values.type === 'project_completion' && values.recurrence !== 'once') {
            errors.recurrence = 'Project completion goals can only be set once';
        }

        if (values.type !== 'project_completion' && values.recurrence === 'once') {
            errors.recurrence = 'Only project completion goals can be set once';
        }

        if (values.type === 'project_completion' && !isValid(new Date(values.target))) {
            errors.target = 'The target must be a valid date in the format YYYY-MM-DD';
        }

        if (values.type !== 'project_completion' && isNaN(Number(values.target))) {
            errors.target = 'The target must be a number';
        }

        return errors;
    };

    const onSubmit = async (values: NewGoal, {setSubmitting}: FormikHelpers<NewGoal>) => {
        await fetcher('/api/v1/goals', {
            method: 'POST',
            body: JSON.stringify({
                type: values.type,
                target: values.target,
                recurrence: values.recurrence,
            })
        });

        await mutate('/api/v1/goals');
        setSubmitting(false);
        enqueueSnackbar('Your goal was created!', {variant: 'success'});
        navigate('/goals');
    }

    return (
        <Page title={<PageTitle title="Set a new goal"/>}>
            <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                {({values}) => (
                    <Form>
                        <FormFieldset>
                            <FormInput name="type" label="Type">
                                <Field as={RadioGroup} name="type">
                                    <RadioInput
                                        value="word_count"
                                        label="Word count"
                                        helpText="Aim to write a certain number of words"/>

                                    <RadioInput
                                        value="entry_count"
                                        label="Entry count"
                                        helpText="Aim to write a certain number of entries"/>

                                    <RadioInput
                                        value="project_completion"
                                        label="Project completion"
                                        helpText="Complete a project before an optional deadline"/>
                                </Field>
                            </FormInput>

                            <FormInput name="target" label="Target">
                                <Field as={Input}
                                       name="target"
                                       sx={{
                                           maxWidth: '200px'
                                       }}
                                       slotProps={{
                                           input: values.type == 'project_completion'
                                               ? {min: format(new Date(), 'yyyy-MM-dd')}
                                               : {min: 1, step: 1},
                                       }}
                                       type={values.type === 'project_completion' ? 'date' : 'number'}
                                />
                            </FormInput>

                            <FormInput name="recurrence" label="Recurrence">
                                <GoalTypeRadioGroup/>
                            </FormInput>
                        </FormFieldset>

                        <FormActions>
                            <FormSubmit>
                                Save
                            </FormSubmit>
                        </FormActions>
                    </Form>
                )}
            </Formik>
        </Page>
    );
}

function GoalTypeRadioGroup() {
    const {touched, values: {recurrence}, setFieldValue} = useFormikContext<NewGoal>();
    const [field] = useField('type');

    useEffect(() => {
        if (recurrence === 'once' && field.value !== 'project_completion') {
            setFieldValue('recurrence', 'daily');
        } else if (recurrence !== 'once' && field.value === 'project_completion') {
            setFieldValue('recurrence', 'once');
        }
    }, [recurrence, touched.recurrence, setFieldValue, field.value]);

    return (
        <Field as={RadioGroup} name="recurrence">
            <RadioInput
                disabled={field.value !== 'project_completion'}
                value="once"
                label="Once"
                helpText="A one-time goal"/>

            <RadioInput
                disabled={field.value === 'project_completion'}
                value="daily"
                label="Daily"
                helpText="Reset this goal every day"/>

            <RadioInput
                disabled={field.value === 'project_completion'}
                value="weekly"
                label="Weekly"
                helpText="Reset this goal every week, on Monday"/>

            <RadioInput
                disabled={field.value === 'project_completion'}
                value="monthly"
                label="Monthly"
                helpText="Reset this goal every month, on the 1st"/>
        </Field>
    );
}
