import {useSerial} from "../../api";
import {useParams} from "react-router-dom";
import Page from "../Page";
import PageTitle from "../PageTitle";
import LoadingScreen from "../LoadingScreen";

type SerialScreenParams = {
    id: string;
}

export default function SerialScreen() {
    const params = useParams<SerialScreenParams>() as SerialScreenParams;
    const {serial, isError, isLoading} = useSerial(params.id);

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (isError) {
        return <div>Error!</div>;
    }

    if (!serial) {
        return <div>Serial not found</div>;
    }

    return (
        <Page title={ <PageTitle title={ serial.name } /> }>
            {
                serial.entries.map(entry => {
                    return <div key={entry.date}>{entry.date}</div>
                })
            }
        </Page>
    )
}
