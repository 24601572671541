import {useSeries} from "../../api";
import Page from "../Page";
import {StackedListItem} from "../StackedListItem";
import {StackedList} from "../StackedList";
import Chip from "@mui/joy/Chip";
import PageTitle from "../PageTitle";
import LoadingScreen from "../LoadingScreen";

export default function SeriesScreen() {
    const {series: allSeries, isError, isLoading} = useSeries();

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (isError) {
        return <div>Error!</div>;
    }

    if (!allSeries) {
        return <div>There are no series</div>;
    }

    const series = allSeries.items.map(serial => {
        return (
            <StackedListItem key={serial.id}
                             href={`/series/${serial.id}`}
                             title={serial.name}
                             titleRight={<Chip>{serial.word_count} words</Chip>}
                             subtitle={<Chip>{serial.entry_count} posts</Chip>}
                             subtitleRight={<Chip>{serial.char_count} characters</Chip>}/>
        );
    });

    return (
        <Page title={ <PageTitle title="All series" /> }>
            <StackedList>
                {series}
            </StackedList>
        </Page>
    );
}
