import { extendTheme } from '@mui/joy/styles';

const theme = extendTheme({
    "colorSchemes": {
        "light": {
            "palette": {}
        },
        "dark": {
            "palette": {
                "background": {
                    "body": "var(--joy-palette-neutral-800)",
                    "surface": "var(--joy-palette-neutral-700)"
                }
            }
        }
    }
})

export default theme;
