import {useTags} from "../../api";
import pluralize from "pluralize";
import Page from "../Page";
import {StackedList} from "../StackedList";
import {StackedListItem} from "../StackedListItem";
import Chip from "@mui/joy/Chip";
import PageTitle from "../PageTitle";
import LoadingScreen from "../LoadingScreen";

export default function TagsScreen() {
    const {tags: allTags, isError, isLoading} = useTags();

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (isError) {
        return <div>Error!</div>;
    }

    if (!allTags) {
        return <div>There are no tags</div>;
    }

    const tags = allTags.items.map(tag => {
        const title = (
            <Chip style={{background: tag.colour_background, color: tag.colour_foreground}}>
                {tag.name}
            </Chip>
        );

        return (
            <StackedListItem key={tag.id}
                             href={`/tags/${tag.id}`}
                             title={title}
                             titleRight={<Chip>{tag.word_count} {pluralize('word', tag.word_count)}</Chip>}
                             subtitle={<Chip>{tag.entry_count} {pluralize('post', tag.entry_count)}</Chip>}
                             subtitleRight={<Chip>{tag.char_count} {pluralize('character', tag.char_count)}</Chip>}/>
        );
    });

    return (
        <Page title={ <PageTitle title="All tags" /> }>
            <StackedList>
                {tags}
            </StackedList>
        </Page>
    );
}
