import {ErrorMessage, useField} from "formik";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import React from "react";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

interface FormInputProps {
    children?: React.ReactNode;
    helpText?: string;
    name: string;
    label: string;
}

export default function FormInput({children, helpText, name, label}: FormInputProps) {
    const [, meta,] = useField(name);

    const error = Boolean(meta.error && meta.touched);

    return (
        <FormControl error={error}>
            <FormLabel htmlFor={name} id={`${name}-label`}>
                {label}
            </FormLabel>

            {children}

            {helpText && (
                <FormHelperText>
                    {helpText}
                </FormHelperText>
            )}

            <ErrorMessage name={name}>
                {message => (
                    <FormHelperText>
                        <InfoOutlined/> {message}
                    </FormHelperText>
                )}
            </ErrorMessage>
        </FormControl>
    )
}
