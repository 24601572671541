import CircularProgress from '@mui/joy/CircularProgress';
import Stack from "@mui/joy/Stack";

export default function LoadingScreen() {
    return (
        <Stack direction="row" alignItems="center" justifyContent="center">
            <CircularProgress />
        </Stack>
    )
}
