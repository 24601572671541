import './app.css'
import { Outlet } from "react-router-dom";
import { ProvideAuth } from "./hooks/useAuth";
import { Authenticator } from "@aws-amplify/ui-react";
import Header from "./components/Header";
import Layout from "./components/Layout";
import * as Sentry from "@sentry/react";

export function App() {
    return (
        <Authenticator>
            { ({ signOut }) => {
                if (!signOut) {
                    Sentry.captureException(new Error("No sign out function was given. This should never happen!"));
                    return <>No sign out function was given. This should never happen!</>;
                }

                return (
                    <ProvideAuth>
                        <Layout.Root>
                            <Layout.Header>
                                <Header signOut={ signOut } />
                            </Layout.Header>
                            <Layout.Main>
                                <Outlet/>
                            </Layout.Main>
                        </Layout.Root>
                    </ProvideAuth>
                );
            } }

        </Authenticator>
    )
}
