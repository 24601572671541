import {useEffect, useState} from "react";
import {Entry, EntryType, fetcher} from "../../api.ts";
import {mutate} from "swr";
import CircularProgress from "@mui/joy/CircularProgress";
import Textarea from "@mui/joy/Textarea";
import Editor from "./Editor.tsx";
import pluralize from "pluralize";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import {useDebouncedCallback} from "use-debounce";

interface EntryEditProps {
    content: string | null;
    id: number | null;
    type: EntryType | null;
    serial: string | null;
    tags: string[];
}

export function EntryEdit(props: EntryEditProps) {
    const [id, setId] = useState<number | null>(props.id);
    const [content, setContent] = useState<string>(props.content || '');
    const [contentType, setContentType] = useState<EntryType>(props.type || 'freeform');
    const [serial, setSerial] = useState<string | null>(props.serial);
    const [tags, setTags] = useState<string[]>(props.tags || []);
    const [saving, setSaving] = useState(false);
    const [savedAt, setSavedAt] = useState<Date | null>(null);

    // Whenever the user tries to close the windows when the editor is open, ask to make sure
    // TODO: Be more clever here, and only display the alert if the content has been modified since the last successful save
    useEffect(() => {
        window.addEventListener("beforeunload", handleUnload);
        return () => {
            window.removeEventListener("beforeunload", handleUnload);
        };
    }, []);

    // Whenever the user switches tabs, or otherwise hides the window (i.e. mobile browsers), save the content
    useEffect(() => {
        window.addEventListener("visibilitychange", async () => {
            if (document.visibilityState === 'hidden') {
                await onChangeContent(content);
            }

            if (document.visibilityState === 'visible') {
                // TODO
            }
        });
        return () => {
            window.removeEventListener("visibilitychange", handleUnload);
        };
    }, []);

    // TODO: Also fire this on page unload (or whatever event it is when the user closes the page)

    const updateContent = async (value: string) => {
        if (value !== undefined) {
            if (id === null) {
                await createEntry();
            } else {
                await patchEntry([
                    {
                        'op': 'replace',
                        'path': '/content',
                        'value': value
                    },
                    {
                        'op': 'replace',
                        'path': '/type',
                        'value': contentType
                    }
                ]);
            }
        }
    }

    const debounceUpdateContent = useDebouncedCallback(updateContent, 1000, {
        maxWait: 5000,
    });

    const handleUnload = async (e: Event) => {
        e.preventDefault();
        // await onChangeContent(content);
    };

    // TODO: Implement mobile-friendly unload handling: https://www.igvita.com/2015/11/20/dont-lose-user-and-app-state-use-page-visibility/

    async function patchEntry(patch: object[]) {
        setSaving(true);
        const entry: Entry = await fetcher(`/api/v1/entries/${id}`, {
            method: 'PATCH',
            body: JSON.stringify(patch)
        });
        await mutate(`/api/v1/entries/${entry.id}`, entry);
        setSaving(false);
        setSavedAt(new Date());
    }

    async function createEntry() {
        setSaving(true);
        const entry: Entry = await fetcher(`/api/v1/entries`, {
            method: 'POST',
            body: JSON.stringify({
                content: content,
                type: contentType,
                series: {
                    id: serial
                },
                tags: [
                    ...tags.map(id => ({'id': id}))
                ]
            })
        });

        setId(entry.id);
        await mutate(`/api/v1/entries/${entry.id}`, entry);
        setSaving(false);
        setSavedAt(new Date());
    }

    const onChangeContent = async (newContent: string) => {
        setContent(newContent);
        debounceUpdateContent(newContent);
    };

    const wordCount = content.trim().length === 0 ? 0 : content.split(/\s+\b/).length;

    // TODO: Notify if the date crosses over into tomorrow (or past the user's set "end of day" threshold)
    // TODO: Store content in local storage or something until it's saved

    const onChangeSerial = async (serial: string | null) => {
        setSerial(serial);

        if (id) {
            await patchEntry([
                serial
                    ? {'op': 'replace', 'path': '/series', 'value': {'id': serial}}
                    : {'op': 'remove', 'path': '/series'}
            ]);
        }
    }

    const onChangeTags = async (tags: string[]) => {
        setTags(tags);

        if (id) {
            await patchEntry([
                {
                    'op': 'replace',
                    'path': '/tags',
                    'value': tags.map(tag => ({'id': tag}))
                }
            ]);
        }
    }

    let savingElement;
    if (saving) {
        savingElement = <CircularProgress sx={{
            "--CircularProgress-size": "16px",
            "--CircularProgress-trackThickness": "2px",
            "--CircularProgress-progressThickness": "2px"
        }}/>;
    } else if (!saving) {
        // TODO: If saving errors, this says the wrong thing!
        savingElement = savedAt
            ? <span>Saved at {savedAt.toLocaleTimeString()}</span>
            : <span>Not saved yet</span>;
    }

    const editor = (
        <>
            <Sheet
                variant="outlined"
                sx={{borderRadius: 'sm', gap: 2, p: 1, backgroundColor: 'background.level1'}}
            >
                <Typography level="body-sm">
                    <Stack direction="row" justifyContent="space-between">
                        <Box>
                            {wordCount} {pluralize('word', wordCount)}
                        </Box>
                        <Box>
                            {savingElement}
                        </Box>
                    </Stack>
                </Typography>
            </Sheet>

            <Box sx={{paddingTop: 1}}>
                {/* TODO: There is a focus outline here for some reason */}
                <Textarea id="typewriter" autoFocus autoComplete="off"
                          defaultValue={content}
                          onChange={e => onChangeContent(e.target.value)}
                          placeholder="Start writing something..."
                    // TODO: Height should be 100% - height of toolbar
                          sx={{height: '70vh'}}
                />
            </Box>
        </>
    );

    return (
        <Editor onChangeTags={onChangeTags} onChangeSeries={onChangeSerial} onChangeType={setContentType}
                serial={serial} tags={tags} type={contentType}>
            {editor}
        </Editor>
    );
}
