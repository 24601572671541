import {Goal, useGoals} from "../../api.ts";
import Page from "../Page";
import PageTitle from "../PageTitle";
import pluralize from "pluralize";
import {toNumber} from "lodash";
import Card from "@mui/joy/Card";
import {CardContent, LinearProgress} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import {format, isEqual, startOfMonth, startOfWeek} from "date-fns";
import Box from "@mui/joy/Box";
import {Add, CheckCircle, CheckCircleOutline, Edit, PendingOutlined, Unpublished} from "@mui/icons-material";
import IconButton from "@mui/joy/IconButton";
import Tooltip from "@mui/joy/Tooltip";
import {formatDate, getToday} from "../../utils.ts";
import React from "react";
import {Link} from "react-router-dom";
import Button from "@mui/joy/Button";
import {OverridableStringUnion} from "@mui/types";
import {ColorPaletteProp} from "@mui/joy/styles/types";
import {LinearProgressPropsColorOverrides} from "@mui/joy/LinearProgress/LinearProgressProps";

export default function GoalsScreen() {
    const {goals, isLoading, isError} = useGoals();

    if (isError) {
        // TODO
        return <p>Error</p>;
    }

    if (!goals) {
        // TODO
        return <p>Loading...</p>;
    }

    return (
        <Page title={
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <PageTitle title="Goals"/>

                <Box>
                    <Button component={Link} variant="soft" color="primary" startDecorator={<Add/>} to="/goals/new">
                        Set a new goal
                    </Button>
                </Box>
            </Stack>
        } loading={isLoading}>
            <Stack direction="column" spacing={1}>
                {
                    goals.items.map((goal) => (
                        <GoalItem key={goal.id} goal={goal}/>
                    ))
                }
            </Stack>
        </Page>
    );
}

interface GoalItemProps {
    goal: Goal;
}

const GoalItem = ({goal}: GoalItemProps) => {
    switch (goal.type) {
        case "project_completion":
            return <ProjectCompletionGoalItem goal={goal}/>;
        case "word_count":
            return <WordCountGoalItem goal={goal}/>;
        case "entry_count":
            return <EntryCountGoalItem goal={goal}/>;
        default:
            // TODO
            return <></>;
    }
}

interface ProjectCompletionGoalItemProps {
    goal: Goal;
}

const ProjectCompletionGoalItem = ({goal}: ProjectCompletionGoalItemProps) => {
    // TODO: This component should display whether the project was completed after the deadline or not
    const isCompleted = goal.progress.length > 0;

    const actions = (
        <Tooltip title={`Mark as ${isCompleted ? 'incomplete' : 'complete'}`}>
            <IconButton variant="plain" color="neutral" size="sm">
                {isCompleted ? <Unpublished/> : <CheckCircle/>}
            </IconButton>
        </Tooltip>
    );

    return <ProgressCard
        actions={actions}
        completed={isCompleted}
        progress={isCompleted ? 100 : 0}
        title={`Finish the project ${goal.target} by ${goal.ends_at}`}
        subtitle={isCompleted ? `Completed on ${formatDate(goal.progress[0].date)}` : `Not completed yet`}
    />;
}

interface WordCountGoalItemProps {
    goal: Goal;
}

const WordCountGoalItem = ({goal}: WordCountGoalItemProps) => {
    const today = new Date(format(new Date(getToday()), 'yyyy-MM-dd'));
    const targetWordCount = toNumber(goal.target);

    let currentWordCount = 0;
    if (goal.recurrence === "daily") {
        const todaysProgress = goal.progress.find((p) => isEqual(new Date(p.date), today));
        currentWordCount = todaysProgress?.count || 0;
    } else if (goal.recurrence === "weekly") {
        const weekStart = startOfWeek(today, {weekStartsOn: 1});
        const thisWeek = goal.progress.find(p => isEqual(new Date(p.date), weekStart));
        currentWordCount = thisWeek?.count || 0;
    } else if (goal.recurrence === "monthly") {
        const monthStart = startOfMonth(today);
        const thisMonth = goal.progress.find(p => isEqual(new Date(p.date), monthStart));
        currentWordCount = thisMonth?.count || 0;
    }

    const progress = Math.min(100, 100 / targetWordCount * currentWordCount);
    const isCompleted = currentWordCount >= targetWordCount;

    return <ProgressCard
        completed={isCompleted}
        progress={progress}
        title={`Write ${goal.target} words per ${convertToRecurrence(goal.recurrence)}`}
        subtitle={`${currentWordCount}/${targetWordCount} words so far ${convertToCurrentTimeRange(goal.recurrence)}`}
    />;
}

interface ProgressCardProps {
    actions?: React.ReactNode;
    completed: boolean;
    progress: number;
    title: string;
    subtitle: string;
}

function ProgressCard({actions, completed, progress, title, subtitle}: ProgressCardProps) {
    return (
        <Card variant="outlined" sx={{mb: 2, boxShadow: 3}}>
            <CardContent sx={{p: 1.5}}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography level="h4"
                                startDecorator={completed ? <CheckCircleOutline color="success" fontSize="large"/> :
                                    <PendingOutlined fontSize="large"/>}>
                        {title}
                    </Typography>

                    <Stack direction="row" spacing={0.5} alignItems="center">
                        {actions}

                        <Tooltip title="Edit goal">
                            <IconButton variant="plain" color="neutral" size="sm">
                                <Edit/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>

                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography level="body-sm">
                        {subtitle}
                    </Typography>
                </Stack>

                <Box sx={{mt: 1}}>
                    <LinearProgress determinate value={progress} color={getProgressColor(progress)} variant="soft"/>
                </Box>
            </CardContent>
        </Card>
    );
}

function getProgressColor(progress: number): OverridableStringUnion<ColorPaletteProp, LinearProgressPropsColorOverrides> {
    if (progress === 100) {
        return "success";
    } else if (progress > 25) {
        return "warning";
    } else {
        return "danger";
    }
}

interface EntryCountGoalItemProps {
    goal: Goal;
}

const EntryCountGoalItem = ({goal}: EntryCountGoalItemProps) => {
    const today = new Date(format(new Date('2024-01-08'), 'yyyy-MM-dd'));
    const target = toNumber(goal.target);

    let current = 0;
    if (goal.recurrence === "daily") {
        const thisDay = goal.progress.find((p) => isEqual(new Date(p.date), today));
        current = thisDay?.count || 0;
    } else if (goal.recurrence === "weekly") {
        const weekStart = startOfWeek(today, {weekStartsOn: 1});
        const thisWeek = goal.progress.find(p => isEqual(new Date(p.date), weekStart));
        current = thisWeek?.count || 0;
    } else if (goal.recurrence === "monthly") {
        const monthStart = startOfMonth(today);
        const thisMonth = goal.progress.find(p => isEqual(new Date(p.date), monthStart));
        current = thisMonth?.count || 0;
    }

    const progress = Math.min(100, 100 / target * current);
    const isCompleted = current >= target;

    return <ProgressCard
        completed={isCompleted}
        progress={progress}
        title={`Complete ${goal.target} ${pluralize('entry', target)} per ${convertToRecurrence(goal.recurrence)}`}
        subtitle={`${current}/${target} ${pluralize('entry', target)} so far ${convertToCurrentTimeRange(goal.recurrence)}`}
    />;
}

function convertToRecurrence(recurrence: string): string {
    if (recurrence === "daily") {
        return "day";
    } else if (recurrence === "weekly") {
        return "week";
    } else if (recurrence === "monthly") {
        return "month";
    }

    // TODO
    return "";
}

function convertToCurrentTimeRange(recurrence: string): string {
    if (recurrence === "daily") {
        return "today";
    } else if (recurrence === "weekly") {
        return "this week";
    } else if (recurrence === "monthly") {
        return "this month";
    }

    // TODO
    return "";
}
