import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from "@sentry/react";
import {App} from './app'
import './index.css'
import {
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    RouterProvider,
    useLocation,
    useNavigationType
} from "react-router-dom";
import StatsScreen from "./components/StatsScreen";
import TodayScreen from "./components/TodayScreen";
import EntriesScreen from "./components/EntriesScreen";
import SeriesScreen from "./components/SeriesScreen";
import TagsScreen from "./components/TagsScreen";
import TagScreen from "./components/TagsScreen";
import EntryScreen from "./components/EntryScreen";
import SerialScreen from "./components/SerialScreen";
import ProfileScreen from "./components/ProfileScreen";
import SettingsScreen from "./components/SettingsScreen";
import {Amplify} from "aws-amplify";
import '@aws-amplify/ui-react/styles.css';
// @ts-expect-error: This is an AWS export that has nothing to do with us
import awsExports from "./aws-exports";
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import {CssBaseline, CssVarsProvider, styled} from "@mui/joy";
import GoalsScreen from "./components/GoalsScreen";
import NewGoalScreen from "./components/NewGoalScreen";
import theme from "./theme";
import {MaterialDesignContent, SnackbarProvider} from "notistack";
import NewEntryScreen from "./components/NewEntryScreen";
import EntryEditScreen from "./components/EntryEditScreen";

Sentry.init({
    dsn: "https://bfb3de9f0eae3d3c09430c71c13ce74a@o103099.ingest.sentry.io/4506623152750592",
    integrations: [
        new Sentry.BrowserTracing({
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const userPoolId = import.meta.env.PROD
    ? 'eu-west-1_nwnPH7w0P'
    : 'eu-west-1_lLITzmo7E';

const userPoolWebClientId = import.meta.env.PROD
    ? '5i3prsudejhb9edtmeif00t2pb'
    : '225b99a7imqqd8rs4o8n4c9el9';

const oauthDomain = import.meta.env.PROD
    ? 'writual-prod.auth.eu-west-1.amazoncognito.com'
    : 'writual-dev.auth.eu-west-1.amazoncognito.com';

Amplify.configure({
    ...awsExports,
    'aws_user_pools_id': userPoolId,
    'aws_user_pools_web_client_id': userPoolWebClientId,
    'oauth': {
        'domain': oauthDomain
    }
});

const router = createBrowserRouter([
    {
        path: '/',
        element: <App/>,
        children: [
            {
                path: '/',
                element: <TodayScreen/>,
            },
            {
                path: 'entries',
                element: <EntriesScreen/>,
            },
            {
                path: 'entries/new',
                element: <NewEntryScreen/>
            },
            {
                path: 'entries/:id',
                element: <EntryScreen/>
            },
            {
                path: 'entries/:id/edit',
                element: <EntryEditScreen/>
            },
            {
                path: 'goals',
                element: <GoalsScreen/>,
            },
            {
                path: 'goals/new',
                element: <NewGoalScreen/>,
            },
            {
                path: 'profile',
                element: <ProfileScreen/>
            },
            {
                path: 'series',
                element: <SeriesScreen/>
            },
            {
                path: 'series/:id',
                element: <SerialScreen/>
            },
            {
                path: 'settings',
                element: <SettingsScreen/>
            },
            {
                path: 'tags',
                element: <TagsScreen/>
            },
            {
                path: 'tags/:tag',
                element: <TagScreen/>
            },
            {
                path: 'stats',
                element: <StatsScreen/>
            }
        ]
    }
]);

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({theme}) => ({
    '&.notistack-MuiContent': {
        borderRadius: theme.radius.sm,
        boxShadow: theme.shadow.lg,
        fontFamily: theme.fontFamily.body,
        fontSize: theme.fontSize.sm,
        lineHeight: theme.lineHeight.md,
        padding: '0.75rem 1rem'
    },
    '&.notistack-MuiContent-default': {
        backgroundColor: theme.palette.neutral.softBg,
        color: theme.palette.neutral.softColor,
    },
    '&.notistack-MuiContent-success': {
        backgroundColor: theme.palette.success.softBg,
        color: theme.palette.success.softColor,
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: theme.palette.danger.softBg,
        color: theme.palette.danger.softColor,
    },
    '&.notistack-MuiContent-warning': {
        backgroundColor: theme.palette.warning.softBg,
        color: theme.palette.warning.softColor,
    },
    '&.notistack-MuiContent-info': {
        backgroundColor: theme.palette.primary.softBg,
        color: theme.palette.primary.softColor,
    }
}));

const app = (
    <React.StrictMode>
        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
            <CssVarsProvider theme={theme}>
                <CssBaseline/>

                <SnackbarProvider
                    autoHideDuration={3000}
                    maxSnack={3}
                    Components={{
                        default: StyledMaterialDesignContent,
                        success: StyledMaterialDesignContent,
                        error: StyledMaterialDesignContent,
                        warning: StyledMaterialDesignContent,
                        info: StyledMaterialDesignContent,
                    }}>
                    <RouterProvider router={router}/>
                </SnackbarProvider>
            </CssVarsProvider>
        </Sentry.ErrorBoundary>
    </React.StrictMode>
);

// @ts-expect-error: This will almost definitely never be null
ReactDOM.createRoot(document.getElementById('app')).render(app);
