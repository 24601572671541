import FormHelperText from '@mui/joy/FormHelperText';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import React from "react";
import {SelectValue} from "@mui/base/useSelect";

interface FormSelectProps {
    children: React.ReactNode;
    defaultValue?: string;
    helpText?: string;
    label: string;
    name: string;
    onBlur?: (event: React.FocusEvent) => void;
    onChange?: (event: React.MouseEvent | React.KeyboardEvent | React.FocusEvent | null, value: SelectValue<string, string>) => void;
    value: string;
}

export default function FormSelect({children, helpText, label, name, ...props}: FormSelectProps) {
    return (
        <>
            <FormLabel htmlFor={name} id={`${name}-label`}>
                {label}
            </FormLabel>
            <Select
                slotProps={{
                    button: {
                        id: name,
                        'aria-labelledby': `${name}-label ${name}`,
                    }
                }}
                {...props}
                id={name}
                name={name}
            >
                {children}
            </Select>
            <FormHelperText>
                {helpText}
            </FormHelperText>
        </>
    );
}
