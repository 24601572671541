import {EntryEdit} from "../EntryScreen/EntryEdit.tsx";
import {useParams} from "react-router-dom";
import {useEntry} from "../../api.ts";
import {toNumber} from "lodash";
import LoadingScreen from "../LoadingScreen";
import Page from "../Page";
import PageTitle from "../PageTitle";

type EntryEditParams = {
    id: string;
}

export default function EntryEditScreen() {
    const {id} = useParams<EntryEditParams>() as EntryEditParams;
    const {entry, isError, isLoading} = useEntry(toNumber(id));

    if (isLoading) {
        return <LoadingScreen/>;
    }

    if (isError) {
        // TODO
        return <div>Unable to load entry</div>;
    }

    if (!entry) {
        // TODO
        return <div>Entry not found</div>;
    }

    return (
        <Page maxWidth="xl" title={<PageTitle title="Edit Entry"/>}>
            <EntryEdit id={entry.id}
                       content={entry.content}
                       serial={entry.series?.id}
                       tags={entry.tags.map(t => t.id)}
                       type={entry.type}/>
        </Page>
    );
}
