import Page from "../Page";
import FormFieldset from "../FormFieldset";
import FormInput from "../FormInput";
import {Field, Form, Formik, FormikHelpers} from "formik";
import FormSubmit from "../FormSubmit";
import FormActions from "../FormActions";
import {useAuth} from "../../hooks/useAuth";
import PageTitle from "../PageTitle";
import Input from "@mui/joy/Input";
import {useSnackbar} from "notistack";

interface ProfileAttributes {
    name: string;
    email: string;
}

export default function ProfileScreen() {
    const {loading, attributes, updateAttributes} = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    const initialValues: ProfileAttributes = {
        name: attributes.name || '',
        email: attributes.email || ''
    }

    const validate = () => {
        // TODO: Do we need any validation here?
        return {};
    };

    const onSubmit = async (values: ProfileAttributes, {setSubmitting}: FormikHelpers<ProfileAttributes>) => {
        try {
            await updateAttributes(values);
        } catch (error) {
            // TODO
            console.log(error);
        }

        setSubmitting(false);
        enqueueSnackbar('Your profile was saved!', {variant: 'success'});
    }

    return (
        <Page loading={loading} title={<PageTitle title="Your profile"/>}>
            <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                <Form>
                    <FormFieldset>
                        <FormInput name="name" label="Name" helpText="What should we call you?">
                            <Field as={Input} name="name"/>
                        </FormInput>

                        <FormInput name="email" label="Email">
                            <Field as={Input} name="email" type="email" disabled={true}/>
                        </FormInput>
                    </FormFieldset>

                    <FormActions>
                        <FormSubmit>
                            Save
                        </FormSubmit>
                    </FormActions>
                </Form>
            </Formik>
        </Page>
    );
}
