import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import React from "react";

interface StackedListProps {
    children: React.ReactNode[];
}

export function StackedList({children}: StackedListProps) {
    return (
        <List variant="outlined" sx={{
            minWidth: 240,
            borderRadius: 'sm',
        }}>
            {
                children.map((child, index) => {
                    return (
                        <>
                            {child}

                            {index < children.length - 1 && <ListDivider/>}
                        </>
                    );
                })
            }
        </List>
    );
}
