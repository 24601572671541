import Box from "@mui/joy/Box";
import Select, {SelectStaticProps} from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import {useRef, useState} from "react";
import {fetcher, Series, useSeries} from "../../api.ts";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import {Add, CloseRounded} from "@mui/icons-material";
import Typography from "@mui/joy/Typography";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import ModalClose from "@mui/joy/ModalClose";
import Input from "@mui/joy/Input";
import {Field, Form, Formik, FormikErrors, FormikHelpers} from "formik";
import {mutate} from "swr";
import IconButton from "@mui/joy/IconButton";
import FormSubmit from "../FormSubmit.tsx";

interface AddSerialRequest {
    name: string;
}

interface AddSerialModalProps {
    onSave: (id: string) => void;
}

export function AddSerialModal({onSave}: AddSerialModalProps) {
    const [open, setOpen] = useState<boolean>(false);

    const initialValues: AddSerialRequest = {
        name: ''
    }

    const validate = (values: AddSerialRequest) => {
        const errors: FormikErrors<AddSerialRequest> = {};

        if (!values.name) {
            errors.name = 'Required';
        }

        return errors;
    };

    const onSubmit = async (values: AddSerialRequest, {setSubmitting}: FormikHelpers<AddSerialRequest>) => {
        const serial: Series = await fetcher('/api/v1/series', {
            method: 'POST',
            body: JSON.stringify({
                name: values.name
            })
        })

        await mutate('/api/v1/series');

        onSave(serial.id);
        setSubmitting(false);
        setOpen(false);
    };

    return (
        <>
            <Button variant="plain" startDecorator={<Add/>} onClick={() => setOpen(true)}>
                Add
            </Button>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={() => setOpen(false)}
                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{m: 1}}/>
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1.5}
                    >
                        Add new serial
                    </Typography>
                    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                        <Form>
                            <Typography id="modal-desc" textColor="text.tertiary">
                                <Field as={Input} required name="name" endDecorator={
                                    <FormSubmit inline>Save</FormSubmit>
                                }/>
                            </Typography>
                        </Form>
                    </Formik>
                </Sheet>
            </Modal>
        </>
    );
}

interface SelectSerialProps {
    onChange: (id: string | null) => void;
    value: string | null;
}

export default function SelectSerial({onChange, value}: SelectSerialProps) {
    const [selected, setSelected] = useState<string | null>(value);
    const {series, isError, isLoading} = useSeries();
    const action: SelectStaticProps['action'] = useRef(null);

    if (isError) {
        // TODO
        return <div>Error!</div>
    }

    if (isLoading) {
        // TODO
        return <div>Loading...</div>
    }

    if (!series) {
        // TODO
        return <div>Something went wrong</div>
    }

    const onChangeSerial = (id: string | null) => {
        setSelected(id);
        onChange(id);
    }

    const options = series && series.items.map(t => (
        <Option key={t.id} value={t.id}>{t.name}</Option>
    ));

    return (
        <Box>
            <Stack direction="row" display="flex" spacing={1}>
                <Box flexGrow={1}>
                    <Select
                        onChange={(_e, v) => onChangeSerial(v)}
                        placeholder="Pick a serial"
                        value={selected}
                        {...(selected && {
                            endDecorator: (
                                <IconButton
                                    size="sm"
                                    variant="plain"
                                    color="neutral"
                                    onMouseDown={(event) => {
                                        // don't open the popup when clicking on this button
                                        event.stopPropagation();
                                    }}
                                    onClick={() => {
                                        onChangeSerial(null);
                                        action.current?.focusVisible();
                                    }}
                                >
                                    <CloseRounded/>
                                </IconButton>
                            ),
                            indicator: null,
                        })}
                    >
                        {options}
                    </Select>
                </Box>

                <AddSerialModal onSave={id => onChangeSerial(id)}/>
            </Stack>
        </Box>
    )
}
