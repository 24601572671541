import {useParams} from "react-router-dom";
import {useEntry} from '../../api';
import Page from "../Page";
import LoadingScreen from "../LoadingScreen";
import {EntryView} from "./EntryView.tsx";
import {toNumber} from "lodash";

type EntryScreenParams = {
    id: string;
}

export default function EntryScreen() {
    // TODO: Use a loader?
    const params = useParams<EntryScreenParams>() as EntryScreenParams;
    const {entry, isError, isLoading} = useEntry(toNumber(params.id));

    if (isLoading) {
        return <LoadingScreen/>
    }

    if (isError) {
        // TODO
        return <div>Error loading entry</div>;
    }

    // TODO: Expose these in the API as _meta
    // const dateBack = getDayBefore(date);
    // const dateNext = getDayAfter(date);

    return (
        <Page maxWidth="xl" title={
            <div />
            // TODO: Re-implement this
            // <Stack direction="row" justifyContent="space-between">
            //     <div>
            //         <Link to={`/entries/${dateBack}`}>
            //             &lt; {formatDate(dateBack)}
            //         </Link>
            //     </div>
            //     <div>
            //         <strong>
            //             {formatDate(date)}
            //             {date === getToday() && ' (today)'}
            //         </strong>
            //     </div>
            //     <div>
            //         <Link to={`/entries/${dateNext}`}>
            //             {formatDate(dateNext)} &gt;
            //         </Link>
            //     </div>
            // </Stack>
        }>
            <EntryView entry={entry}/>
        </Page>
    )
}
