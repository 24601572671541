import Page from "../Page";
import {Form, Formik, FormikHelpers} from "formik";
import FormSelect from "../FormSelect";
import {fetcher, Settings, useSettings} from "../../api";
import FormSubmit from "../FormSubmit";
import FormActions from "../FormActions";
import Option from "@mui/joy/Option";
import {FormControl} from "@mui/joy";
import PageTitle from "../PageTitle";
import LoadingScreen from "../LoadingScreen";
import {useSnackbar} from "notistack";

export default function SettingsScreen() {
    const {settings, isLoading, isError} = useSettings();
    const { enqueueSnackbar } = useSnackbar();

    if (isLoading) {
        return <LoadingScreen/>;
    }

    if (isError) {
        // TODO
        return <div>Error!</div>;
    }

    const validate = () => {
        // TODO: Do we need any validation here?
        return {};
    };

    const onSubmit = async (values: Settings, {setSubmitting}: FormikHelpers<Settings>) => {
        await fetcher('/api/v1/settings', {
            method: 'PATCH',
            body: JSON.stringify([
                {
                    'op': 'replace',
                    'path': '/reminder',
                    'value': parseInt(values.reminder)
                }
            ])
        })

        setSubmitting(false);
        enqueueSnackbar('Your settings were saved!', {variant: 'success'});
    };

    return (
        <Page title={<PageTitle title="Settings"/>}>
            <Formik initialValues={{reminder: settings?.reminder || ''}} validate={validate} onSubmit={onSubmit}>
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      setFieldValue
                  }) => (

                    <Form>
                        <FormControl error={Boolean(errors.reminder && touched.reminder)}>
                            <FormSelect
                                defaultValue=""
                                helpText="You'll receive a daily reminder at this time to write an entry"
                                label="Daily reminder time"
                                name="reminder"
                                // @ts-expect-error: This _should_ always work, but Joy UI doesn't give us the event that Formik expects
                                onChange={(e, v) => setFieldValue('reminder', v)}
                                onBlur={handleBlur}
                                value={values.reminder}>
                                <Option value="">Disable daily reminders</Option>
                                <Option value="0">12:00am</Option>
                                <Option value="1">1:00am</Option>
                                <Option value="2">2:00am</Option>
                                <Option value="3">3:00am</Option>
                                <Option value="4">4:00am</Option>
                                <Option value="5">5:00am</Option>
                                <Option value="6">6:00am</Option>
                                <Option value="7">7:00am</Option>
                                <Option value="8">8:00am</Option>
                                <Option value="9">9:00am</Option>
                                <Option value="10">10:00am</Option>
                                <Option value="11">11:00am</Option>
                                <Option value="12">12:00pm</Option>
                                <Option value="13">1:00pm</Option>
                                <Option value="14">2:00pm</Option>
                                <Option value="15">3:00pm</Option>
                                <Option value="16">4:00pm</Option>
                                <Option value="17">5:00pm</Option>
                                <Option value="18">6:00pm</Option>
                                <Option value="19">7:00pm</Option>
                                <Option value="20">8:00pm</Option>
                                <Option value="21">9:00pm</Option>
                                <Option value="22">10:00pm</Option>
                                <Option value="23">11:00pm</Option>
                            </FormSelect>
                        </FormControl>

                        <FormActions>
                            <FormSubmit>
                                Save
                            </FormSubmit>
                        </FormActions>
                    </Form>
                )}
            </Formik>
        </Page>
    );
}
