import Typography from "@mui/joy/Typography";

interface PageTitleProps {
    title: string;
}

export default function PageTitle({ title }: PageTitleProps) {
    return (
        <Typography level="h1">
            { title }
        </Typography>
    )
}
