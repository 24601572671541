import React from "react";
import Box from "@mui/joy/Box";
import Container from "@mui/joy/Container";
import Stack from "@mui/joy/Stack";
import LoadingScreen from "../LoadingScreen";
import {Breakpoint} from "@mui/system";

interface PageProps {
    loading?: boolean;
    maxWidth?: Breakpoint | false;
    title: React.ReactNode;
    children: React.ReactNode;
}

export default function Page({loading = false, maxWidth = 'md', title, children}: PageProps) {
    let child;
    if (loading) {
        child = <LoadingScreen/>;
    } else {
        child = children;
    }

    return (
        <Container maxWidth={maxWidth}>
            <Stack direction="column" spacing={3}>
                <Box>
                    {title}
                </Box>

                <Box>
                    {child}
                </Box>
            </Stack>
        </Container>
    );
}

