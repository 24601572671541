import {Link as RouterLink} from "react-router-dom";
import ListItem from '@mui/joy/ListItem';
import Stack from "@mui/joy/Stack";
import React from "react";
import Typography from "@mui/joy/Typography";
import {ListItemButton} from "@mui/joy";

interface StackedListItemProps {
    href: string;
    title: React.ReactNode;
    titleRight?: React.ReactNode;
    subtitle: React.ReactNode;
    subtitleRight?: React.ReactNode;
}

export function StackedListItem({href, title, titleRight, subtitle, subtitleRight}: StackedListItemProps) {
    return (
        <ListItem>
            <ListItemButton component={RouterLink} to={href}>
                <Stack direction="column" padding={1} sx={{width: '100%'}}>
                    <Stack direction="row" justifyContent="space-between" paddingBottom={1}>
                        <Typography level="title-md">
                            {title}
                        </Typography>

                        {titleRight}
                    </Stack>

                    <Stack direction="row" justifyContent="space-between">
                        <div>
                            {subtitle}
                        </div>

                        {subtitleRight}
                    </Stack>
                </Stack>
            </ListItemButton>
        </ListItem>
    );
}
