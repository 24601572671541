import {AccountTree, Create, EmojiEvents, Grading} from "@mui/icons-material";
import ActionGrid, {Action} from "../ActionGrid.tsx";
import Page from "../Page";

export default function TodayScreen() {
    const actions: Action[] = [
        {
            title: "Write an entry",
            description: "Start a new entry for today",
            href: "/entries/new",
            icon: Create,
            iconForeground: "primary.softColor",
            iconBackground: "primary.softBg"
        },
        {
            title: "Work on a series",
            description: "Write and manage a series of entries",
            href: "/series",
            icon: AccountTree,
            iconForeground: "success.softColor",
            iconBackground: "success.softBg"
        },
        {
            title: "Review and edit previous entries",
            description: "Review and edit previous entries",
            href: "/entries",
            icon: Grading,
            iconForeground: "warning.softColor",
            iconBackground: "warning.softBg"
        },
        {
            title: 'Check your goals',
            description: 'Review and edit your goals',
            href: '/goals',
            icon: EmojiEvents,
            iconForeground: 'danger.softColor',
            iconBackground: 'danger.softBg'
        }
    ];

    return (
        <Page maxWidth="sm" title={ <div /> }>
            <ActionGrid actions={ actions } xs={12} />
        </Page>
    )
}
