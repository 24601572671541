import useSWR from 'swr';
import {fetchAuthSession} from "aws-amplify/auth";

const createFetchOptions = async (args: RequestInit = {}): Promise<RequestInit> => {
    const {accessToken} = (await fetchAuthSession()).tokens ?? {};

    return {
        ...args,
        headers: {
            ...args.headers,
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    };
};

class ApiError extends Error {
    status: number;

    constructor(message: string, status: number) {
        super(message);
        this.status = status;
    }
}

export const fetcher = async <Data>(uri: RequestInfo, args?: RequestInit): Promise<Data> => {
    const options = await createFetchOptions(args);
    const response = await fetch(uri, options);

    if (!response.ok) {
        throw new ApiError('An error occurred while fetching the data.', response.status);
    }

    return response.json();
};

const entryFetcher = async (uri: RequestInfo, args?: RequestInit): Promise<Entry | undefined> => {
    const options = await createFetchOptions(args);
    const response = await fetch(uri, options);

    if (response.status === 404) {
        return undefined;
    }

    if (!response.ok) {
        throw new ApiError('An error occurred while fetching the data.', response.status);
    }

    return response.json();
}

export function useEntries() {
    const {data, error, isLoading} = useSWR<Items<Entry, EntryMeta>>('/api/v1/entries', fetcher)

    return {
        entries: data,
        isLoading,
        isError: error
    }
}

export function useEntry(id: number) {
    const {data, error, isLoading} = useSWR<Entry | undefined>(`/api/v1/entries/${id}`, entryFetcher, {
        revalidateOnFocus: false,
        revalidateIfStale: false
    });

    return {
        entry: data,
        isLoading,
        isError: error
    }
}

interface GoalProgress {
    date: string;
    completed: boolean;
    count?: number;
}

export interface Goal {
    id: number;
    type: 'word_count' | 'entry_count' | 'project_completion';
    target: string;
    recurrence: string;
    starts_at: string;
    ends_at: string;
    progress: GoalProgress[];
}

export function useGoals() {
    const {data, error, isLoading} = useSWR<Items<Goal, void>>('/api/v1/goals', fetcher)

    return {
        goals: data,
        isLoading,
        isError: error
    }
}

export function useSerial(id: string) {
    const {data, error, isLoading} = useSWR<Series>(`/api/v1/series/${id}`, fetcher)

    return {
        serial: data,
        isLoading,
        isError: error
    }
}

export function useSeries() {
    const {data, error, isLoading} = useSWR<Items<Series, SeriesMeta>>('/api/v1/series', fetcher)

    return {
        series: data,
        isLoading,
        isError: error
    }
}

interface Stats {
    current_streak: number;
    longest_streak: number;
    total_entries: number;
    total_words: number;
    shortest_entry: number;
    longest_entry: number;
}

export function useStats() {
    const {data, error, isLoading} = useSWR<Stats>('/api/v1/stats', fetcher)

    return {
        stats: data,
        isLoading,
        isError: error
    }
}

interface TagEntry {
    id: string;
    title: string;
    date: string;
    word_count: number;
    char_count: number;
}

export interface Tag {
    id: string;
    name: string;
    colour_background: string;
    colour_foreground: string;
    word_count: number;
    char_count: number;
    entry_count: number;
    entries: TagEntry[];
}

export function useTag(id: string) {
    const {data, error, isLoading} = useSWR<Tag>(`/api/v1/tags/${id}`, fetcher)

    return {
        tag: data,
        isLoading,
        isError: error
    }
}

export function useTags() {
    const {data, error, isLoading} = useSWR<Items<Tag, TagMeta>>('/api/v1/tags', fetcher)

    return {
        tags: data,
        isLoading,
        isError: error
    }
}

interface Prompt {
    text: string;
}

export function useTodaysPrompt() {
    const {data, error, isLoading} = useSWR<Prompt>('/api/v1/prompts', fetcher)

    return {
        prompt: data,
        isLoading,
        isError: error
    }
}

export interface Settings {
    reminder: string;
}

export function useSettings() {
    const {data, error, isLoading} = useSWR<Settings>('/api/v1/settings', fetcher)

    return {
        settings: data,
        isLoading,
        isError: error
    }
}

interface EntryMeta {
    entries_total: number;
    entries_missing: number;
    entries_words: number;
}

export interface EntrySeries {
    id: string;
    name: string;
}

export interface EntryTag {
    id: string;
    name: string;
    colour_background: string;
    colour_foreground: string;
}

export type EntryType = 'freeform' | 'prompt';

export interface Entry {
    id: number;
    type: EntryType;
    title: string;
    body: string;
    date: string;
    content: string;
    word_count: number;
    char_count: number;
    series: EntrySeries;
    tags: EntryTag[];
}

interface SeriesEntry {
    id: string;
    title: string;
    date: string;
    word_count: number;
    char_count: number;
}

interface SeriesMeta {

}

export interface Series {
    id: string;
    name: string;
    description: string;
    entry_count: number;
    word_count: number;
    char_count: number;
    entries: SeriesEntry[];
}

interface TagMeta {

}

interface Items<T, M> {
    meta: M;
    items: T[];
}
