import { useStats } from "../../api";
import pluralize from "pluralize";
import Page from "../Page";
import PageTitle from "../PageTitle";
import LoadingScreen from "../LoadingScreen";

const StatsScreen = () => {
    const { stats, isError, isLoading } = useStats();

    if (isLoading) {
        return <LoadingScreen />;
    }

    if (isError) {
        return <div>Error!</div>;
    }

    if (!stats) {
        return <div>There are no stats</div>;
    }

    const {
        current_streak,
        longest_streak,
        total_entries,
        total_words,
        shortest_entry,
        longest_entry
    } = stats;

    return (
        <Page title={ <PageTitle title="Stats" /> }>
            <div>
                <h5>Current streak</h5>

                {
                    current_streak
                        ? <p>{ current_streak } { pluralize('day', current_streak) }</p>
                        : <p>0 days</p>
                }
            </div>

            <div>
                <h5>Longest streak</h5>
                {
                    longest_streak
                        ? <p>{ longest_streak } { pluralize('day', longest_streak) }</p>
                        : <p>0 days</p>
                }
            </div>

            <div>
                <h5>Total entries</h5>
                <p>{ total_entries }</p>
            </div>
            <div>
                <h5>Total words</h5>
                <p>{ total_words }</p>
            </div>

            {/* TODO: There might be no entries */ }
            <div>
                <h5>Shortest entry</h5>
                <p>
                    {/*TODO*/}
                    {
                        shortest_entry == null
                            ? <span>No entries yet!</span>
                            : <>({ shortest_entry } characters)</>
                    }

                    {/*{*/}
                    {/*    shortest_entry*/}
                    {/*        ? <><a*/}
                    {/*            href={ `/entries/${ shortest_entry.date }` }>{ shortest_entry.date }</a> ({ shortest_entry.char_count } characters)</>*/}
                    {/*        : <span>No entries yet!</span>*/}
                    {/*}*/}
                </p>
            </div>
            <div>
                <h5>Longest entry</h5>
                <p>
                    {/*TODO*/}

                    {
                        longest_entry == null
                            ? <span>No entries yet!</span>
                            : <>({ longest_entry } characters)</>
                    }

                    {/*{*/}
                    {/*    longest_entry*/}
                    {/*        ? <><a*/}
                    {/*            href={ `/entries/${ longest_entry.date }` }>{ longest_entry.date }</a> ({ longest_entry.char_count } characters)</>*/}
                    {/*        : <span>No entries yet!</span>*/}
                    {/*}*/}
                </p>
            </div>
        </Page>
    )
}

export default StatsScreen;
