import Button from '@mui/joy/Button';
import React from "react";
import {useFormikContext} from "formik";

interface FormSubmitProps {
    children: React.ReactNode;
    fullWidth?: boolean;
    inline?: boolean;
}

export default function FormSubmit({children, fullWidth, inline}: FormSubmitProps) {
    const {isSubmitting} = useFormikContext();

    return (
        <Button color="primary"
                fullWidth={fullWidth}
                loading={isSubmitting}
                type="submit"
                disabled={isSubmitting}
                sx={{
                    borderTopRightRadius: inline ? 0 : undefined,
                    borderBottomRightRadius: inline ? 0 : undefined,
                }}
                variant="solid"
        >
            {children}
        </Button>
    );
}
