import Stack from "@mui/material/Stack";
import React from "react";

interface FormActionsProps {
    children: React.ReactNode;
}

export default function FormActions({ children }: FormActionsProps) {
    return (
        <Stack direction="row" justifyContent="end" spacing={ 2 } paddingY={ 2 }>
            { children }
        </Stack>
    );
}
